<template>
  <div
    v-if="caseData"
    class="pa-4"
  >
    <div
      v-for="wb in caseData.workbenches"
      :key="wb._id"
    >
      <h2>{{ wb.name }}</h2>
      <div
        v-for="template in wb.templates"
        :key="template._id"
      >
        <h3>{{ template.name }}</h3>
        <questionnaire-form
          :questionnaire="template.questionnaire"
        />
      </div>
    </div>
    <v-btn
      color="blue darken-1"
      text
      @click="updateQuestionnaires"
    >
      Update
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import QuestionnaireForm from '@/components/forms/QuestionnaireFieldEdit/QuestionnaireForm.vue'

export default {
  components: {
    QuestionnaireForm
  },
  props: {
    caseData: {
      type: Object,
      default: null
    }
  },
  methods: {
    ...mapActions({
      uploadDocument: 'documents/uploadDocument'
    }),
    async uploadDocumentAction (document) {
      const payload = {
        language: document.language,
        file: document.file,
        caseId: this.caseData._id,
        clientId: this.caseData.client._id
      }
      return this.uploadDocument(payload)
    },
    async updateQuestionnaire (questionairre) {
      const filesToUpload = questionairre.fields.filter((f) => {
        return f.fieldType === 'file-input'
      })
      const documents = []
      for (let i = 0; i < filesToUpload.length; i++) {
        const fileData = await this.uploadDocumentAction(filesToUpload[i].value[0])
        documents.push(fileData.data._id)
      }
      const payloadFields = questionairre.fields.filter((f) => {
        return f.fieldType !== 'file-input'
      })
      await this.$axios.post(`/updateQuestionnaire/${questionairre._id}`, {
        fields: payloadFields,
        documents
      })
    },
    async updateQuestionnaires () {
      for (let i = 0; i < this.caseData.workbenches.length; i++) {
        const wb = this.caseData.workbenches[i]
        for (let j = 0; j < wb.templates.length; j++) {
          const template = wb.templates[j]
          await this.updateQuestionnaire(template.questionnaire)
        }
      }
      this.$emit('update')
    }
  }
}
</script>
